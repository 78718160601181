<template>
  <v-card elevation="2" class="pa-6 mt-10">
    <v-card-title>
      Created vacancies
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="vacancies"
      :search="search"
      class="elevation-1"
    ></v-data-table>
  </v-card>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "ID", value: "vacancyId" },
        { text: "Position", value: "position" },
        { text: "Advertised On", value: "advertisedOn" },
        { text: "Added By", value: "departmentId" },
        { text: "Extra Qualifications", value: "additionalQualifications" },
      ],
      vacancies: [],
    };
  },
  mounted() {
    this.getEvents();
  },
  methods: {
    getEvents() {
      axios.get("http://localhost:8091/api/v1/vacancies").then((response) => {
        this.vacancies = response.data;
        console.log(this.vacancies);
      });
    },
  },
};
</script>
