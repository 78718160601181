<template>
  <v-container>
    <v-row>
      <v-col cols="8">
        <view-vacancy />
      </v-col>
      <v-col cols="4">
        <create-vacancy />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CreateVacancy from "../components/CreateVacancy.vue";
import ViewVacancy from "../components/ViewVacancy.vue";
export default {
  components: { CreateVacancy, ViewVacancy },
};
</script>
