<template>
  <v-card elevation="2" class="pa-6 mt-10">
    <h2 class="font-weight-regular mb-4">Create a new Vacancy</h2>
    <v-form v-model="valid" ref="form" @submit.prevent="onSubmit">
      <v-select
        v-model="position"
        :items="positions"
        label="Vacant Position"
        dense
        outlined
        required
        :rules="textRules"
      ></v-select>
      <v-select
        v-model="newspaper"
        :items="newspapers"
        label="Newspaper Name"
        dense
        outlined
        required
        :rules="textRules"
      ></v-select>
      <v-textarea
        v-model="addQualifications"
        label="Additional Qualifications"
        dense
        outlined
        required
        :rules="textRules"
      ></v-textarea>

      <v-select
        v-model="project"
        :items="projects"
        label="Research Project/ Division"
        dense
        outlined
        required
        :rules="textRules"
      ></v-select>

      <v-btn
        class="ma-2 ml-0"
        outlined
        color="indigo"
        :disabled="!valid"
        @click="submit"
      >
        Create Event
      </v-btn>
      <v-btn class="ma-2" outlined color="red" @click="reset">
        Clear
      </v-btn>
    </v-form>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      valid: false,
      textRules: [(v) => !!v || "This field is required"],
      positions: ["Research Assistant", "Volunteer"],
      newspapers: ["Daily News", "Sunday Observer", "Dinamina", "Silumina"],
      projects: ["Natural Products", "Environment Science"],
    };
  },
  methods: {
    reset() {
      this.$refs.form.reset();
    },
    submit() {
      this.$refs.form.validate();
      this.onSubmit();
    },
    onSubmit() {
      axios
        .post("http://localhost:8091/api/v1/vacancy", {
          position: this.position,
          advertisedOn: this.newspaper,
          additionalQualifications: this.addQualifications,
          departmentId: this.project,
          userId: "",
        })
        .then((response) => {
          console.log(response);
          this.$refs.form.reset();
        });
    },
  },
};
</script>
